import React from "react";
import { Router } from "@reach/router";
import { Hero } from "./components/hero";
import { All } from "./components/all";
import "./App.css";

const Homepage = () => {
  return <div>homepage</div>;
};

function App() {
  return (
    <div className="App">
      <Router>
        <Homepage path="/" />
        <All path="heroes/all" />
        <Hero path="heroes/:heroName" />
      </Router>
    </div>
  );
}

export default App;

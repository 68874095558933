import React from "react";

import { Hero } from '../hero'
import styles from "./all.module.scss";

const heroes = ['bear', 'eagle', 'killer_whale', 'lynx', 'puffin', 'seal', 'squirrel', 'volcano', 'weasel']

export const All = ({ heroName }) => {

  return (
    <div className={styles.container}>

      {heroes.map(hero => (
        <div className={styles.hero} key={hero} >

          <Hero heroName={hero} />
        </div>
      ))}

    </div>
  )

}